import { initializeApp } from "firebase/app";
import { isProd } from "./utils/system";
import * as services from "./constants/services";
import queryString from "query-string";
import { installConversionTags } from "./services/conversion/Conversion";
import * as Sentry from "@sentry/browser";
import { installPardot } from "./services/conversion/Pardot";

const firebaseConfig = {
  apiKey: `${services.FirebaseApiKey}`,
  authDomain: `${services.FirebaseAuthDomain}`,
  databaseURL: `${services.FirebaseDatabaseUrl}`,
  projectId: `${services.FirebaseProjectId}`,
  storageBucket: `${services.FirebaseStorageBucket}`,
  messagingSenderId: `${services.FirebaseMessagingSenderId}`,
  appId: `${services.FirebaseAppId}`,
  measurementId: `${services.FirebaseMeasurementId}`,
};

(() => {
  if (typeof window !== "undefined") {
    // production時はconsole.logを吐き出さないようにする
    const disableConsoleLog = isProd && queryString.parse(window.location.search)["console.log"] !== "true";
    if (disableConsoleLog) {
      console.log = () => {
        return "";
      };
      console.error = (
        message?: any /* eslint-disable-line @typescript-eslint/no-explicit-any */,
        ...optionalParams: any[] /* eslint-disable-line @typescript-eslint/no-explicit-any */
      ) => {
        Sentry.captureMessage(message, ...optionalParams);
      };
      console.group = () => {
        return "";
      };
      console.warn = () => {
        return "";
      };
      console.info = () => {
        return "";
      };
      console.dir = () => {
        return "";
      };
      // console.trace = () => {
      //   return "";
      // };
      console.debug = () => {
        return "";
      };
    }

    // initialize firebase
    initializeApp(firebaseConfig);
    installConversionTags();
    installPardot();
  }
})();

"use client";
import { useLocaleContext } from "@rimo/frontend/contexts/LocaleProvider";

import React, { Suspense } from "react";
import styled from "styled-components";
import { useSearchParams } from "next/navigation";
import Link from "next/link";
import { useThemeMode } from "@rimo/ui-old";

interface Props {
  themeEnabled?: boolean;
}

export const SimpleLayout: React.FC<React.PropsWithChildren<Props>> = ({ children, themeEnabled }) => {
  const { locale } = useLocaleContext();
  const themeMode = useThemeMode();
  const logoFilename = themeMode === "dark" && themeEnabled ? "logo_white.svg" : "rimo_logo.svg";

  const LogoWithSearchParams: React.FC = () => {
    const searchParams = useSearchParams();
    const queryString = searchParams?.toString() ? `?${searchParams?.toString()}` : "";

    return (
      <Link href={`/${locale}/${queryString}`} passHref legacyBehavior>
        <Logo>
          <img src={`/assets/${logoFilename}`} alt="logo" />
        </Logo>
      </Link>
    );
  };

  return (
    <Container>
      <Header>
        <Suspense
          fallback={
            <Logo>
              <img src={`/assets/${logoFilename}`} alt="logo" />
            </Logo>
          }
        >
          <LogoWithSearchParams />
        </Suspense>
      </Header>
      {children}
    </Container>
  );
};

const Header = styled.header`
  padding: 16px;
  display: flex;
  align-items: center;
`;

const Logo = styled.a``;
const Container = styled.div`
  background: ${(p) => p.theme.vars.palette.background.paper};
`;

import { VolumeAnalyzer } from "./VolumeAnalyzer";

const SPEAKER_CONTINUE_THRESH = 7;

export class SpeakerFinder {
  private vas: { [key: string]: VolumeAnalyzer };
  private volumes: [number, string][];
  private speaker: string;
  private prevSpeaker: string;
  private speakerContinueCount: number;

  constructor() {
    this.vas = {};
    this.volumes = [];
    this.speaker = "";
    this.prevSpeaker = "";
    this.speakerContinueCount = 0;
  }

  public setStream = (memberId: string, stream: MediaStream | null): void => {
    if (stream) {
      if (this.vas[memberId]) {
        this.vas[memberId].clear();
        delete this.vas[memberId];
      }
      if (stream.getAudioTracks().length > 0) {
        this.vas[memberId] = new VolumeAnalyzer(stream);
      }
    } else {
      delete this.vas[memberId];
    }
  };

  public update = (): void => {
    this.volumes = Object.keys(this.vas).map((streamId) => {
      const va = this.vas[streamId];
      return [va.getVolume().all, streamId];
    });
    if (this.volumes.length === 0) return;
    this.volumes.sort((a, b) => b[0] - a[0]);
    const curSpeaker = this.volumes[0][1];
    if (this.prevSpeaker === curSpeaker) {
      this.speakerContinueCount += 1;
    } else {
      this.prevSpeaker = curSpeaker;
      this.speakerContinueCount = 1;
    }
    if (this.speakerContinueCount >= SPEAKER_CONTINUE_THRESH || this.speaker === "") {
      this.speaker = curSpeaker;
    }
  };

  public volumeRank = (): string[] => {
    return this.volumes.map((v) => {
      return v[1] as string;
    });
  };

  public mainSpeaker = (): string => {
    return this.speaker;
  };
}

// もう少し広く使う場合, config 的なファイルに定義した方がいいかもしれない
const utmParams = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];

const maxAge = 30 * 24 * 60 * 60;

export const pardotFormUrl = "https://go.rimo.app/l/1015852/2023-07-03/frd2";

export function getUtmCookieStrings(query: URLSearchParams): string[] {
  const utmCookieHeaders: string[] = [];

  utmParams.forEach((name) => {
    const value = query.get(name);

    if (typeof value === "string" && value.length > 0) {
      utmCookieHeaders.push(`${name}=${encodeURIComponent(value)}; Max-Age=${maxAge}; Path=/; Secure;`);
    }
  });

  return utmCookieHeaders;
}

export function setUtmParametersToCookie(query: URLSearchParams): void {
  if (typeof window === "undefined") {
    return;
  }

  getUtmCookieStrings(query).forEach((cookie) => {
    document.cookie = cookie;
  });
}

export function createPardotUrlWithUtmParameters(): string {
  if (typeof window === "undefined") {
    return "";
  }

  const search = new URLSearchParams();

  utmParams.forEach((utmParam) => {
    document.cookie.split(";").forEach((field) => {
      const fields = field.trim().split("=");

      if (utmParam === fields[0]) {
        search.append(fields[0], fields[1]);
      }
    });
  });

  if (search.toString() === "") {
    return pardotFormUrl;
  }

  return `${pardotFormUrl}?${search.toString()}`;
}

import { fontBase } from "@rimo/ui-old";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  /* custom */
  html {
    scroll-behavior: smooth;
  }

  /* Fix UI Component */
  input, textarea {
    box-sizing: border-box;
  }
  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  select::-ms-expand {
    display: none;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  body {
    ${fontBase};
    box-sizing: border-box;
  }

  /* for stripe */
  /* Stripe Elements uses iframe so that we cannot use styled-components */
  .StripeElement {
    box-sizing: border-box;
    height: 36px;
    padding: 10px 12px;
    background-color: rgba(0, 0, 0, 0.03);
    font-size: 14px;
    border-radius: 18px;
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  /* this is a rough fix for the first cursor position when the first paragraph is empty */
  .ProseMirror > .ProseMirror-yjs-cursor:first-child {
    margin-top: 16px;
  }
  /* This gives the remote user caret. The colors are automatically overwritten*/
  .ProseMirror-yjs-cursor {
    position: relative;
    margin-left: -1px;
    margin-right: -1px;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-color: orange;
    word-break: normal;
    pointer-events: none;
  }
  /* This renders the username above the caret */
  .ProseMirror-yjs-cursor > div {
    position: absolute;
    top: -1.15em;
    left: -1px;
    font-size: 13px;
    background-color: rgb(250, 129, 0);
    line-height: normal;
    user-select: none;
    color: white;
    padding-left: 2px;
    padding-right: 2px;
    white-space: nowrap;
  }
`;

export default GlobalStyle;

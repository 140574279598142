import { YahooYssConversionId, YahooYssFormConversionLabel, YahooRetargetingID } from "../../constants/services";

function install(scriptId: string) {
  if (document.getElementById(scriptId)) return;

  const { head } = document;
  const script = document.createElement("script");
  script.id = scriptId;
  script.type = "text/javascript";
  script.async = true;
  script.src = `https://s.yimg.jp/images/listing/tool/cv/ytag.js`;
  head.insertBefore(script, head.firstChild);
  // site general
  send({ type: "ycl_cookie" });
  // re-targeting
  send({
    type: "yjad_retargeting",
    config: {
      yahoo_retargeting_id: YahooRetargetingID,
      yahoo_retargeting_label: "",
      yahoo_retargeting_page_type: "",
      yahoo_retargeting_items: [{ item_id: "", category_id: "", price: "", quantity: "" }],
    },
  });
}

export function installYahooTag() {
  install("yahoo-tag");
}

function send(..._: object[]) {
  if (!window.yjDataLayer) {
    window.yjDataLayer = [];
  }
  window.yjDataLayer.push(arguments); // eslint-disable-line prefer-rest-params
}

export function sendYahooFormConversion() {
  if (YahooYssConversionId && YahooYssFormConversionLabel) {
    send({
      type: "yss_conversion",
      config: {
        yahoo_conversion_id: YahooYssConversionId,
        yahoo_conversion_label: YahooYssFormConversionLabel,
        yahoo_conversion_value: "0",
      },
    });
  }
}

"use client";

import React, { createContext, useContext, useReducer, useMemo } from "react";
import { isDev } from "../utils/system";

const popupNames = {
  Payment: "payment",
  Upgrade: "upgrade",
  Subscription: "subscription",
  LimitMessage: "limitMessage",
  DownGrade: "downgrade",
  ConfirmStructure: "confirmStructure",
  ShareInterview: "shareInterview",
  // ADD HERE
} as const;

export type PopupName = (typeof popupNames)[keyof typeof popupNames];

type StateProps = {
  [key in PopupName]: boolean;
};

type ActionType = {
  type: "OPEN" | "CLOSE";
  name: PopupName;
};

const initialState: StateProps = Object.values(popupNames).reduce((acc, name) => {
  acc[name] = false;
  return acc;
}, {} as StateProps);

const reducer: React.Reducer<StateProps, ActionType> = (state, action) => {
  if (isDev) {
    console.group("State(Popups)");
    console.log("action: ", action);
    console.log("current: ", state);
    console.groupEnd();
  }

  switch (action.type) {
    case "OPEN":
      return { ...state, [action.name]: true };
    case "CLOSE":
      return { ...state, [action.name]: false };
    default:
      return state;
  }
};

const StateContext = createContext<StateProps>(initialState);
const DispatchContext = createContext<React.Dispatch<ActionType>>(() => {
  // noop
});

export const usePopupDispatch = () => {
  return useContext(DispatchContext);
};

export const usePopupIsOpen = (name: PopupName) => {
  const state = useContext(StateContext);
  return state[name];
};

export const usePopupActions = (name: PopupName) => {
  const dispatch = useContext(DispatchContext);

  return useMemo(
    () => ({
      open: () => dispatch({ type: "OPEN", name }),
      close: () => dispatch({ type: "CLOSE", name }),
    }),
    [dispatch, name]
  );
};

export const PopupsProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
};

import React, { useContext, useReducer } from "react";

interface LandingPageState {
  planName: string;
  planOptions: string;
}
type ActionProps = {
  type: "SET_PLAN_INFO";
  payload: {
    planName: string;
    planOptions: string;
  };
};

const reducer: React.Reducer<LandingPageState, ActionProps> = (state, action): LandingPageState => {
  switch (action.type) {
    case "SET_PLAN_INFO": {
      const { payload } = action;
      console.log("reducer", payload);
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      console.error("Unknown action:", action);
      return state;
    }
  }
};

const initialState: LandingPageState = {
  planName: "",
  planOptions: "",
};

export const LandingPageStateContext = React.createContext<LandingPageState>(initialState);

export const LandingPageDispatchContext = React.createContext<React.Dispatch<ActionProps>>((() => {
  // noop
}) as React.Dispatch<ActionProps>);

export const LandingPageProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <LandingPageStateContext.Provider value={state}>
      <LandingPageDispatchContext.Provider value={dispatch}>{children}</LandingPageDispatchContext.Provider>
    </LandingPageStateContext.Provider>
  );
};

export function useLandingPageState() {
  return useContext(LandingPageStateContext);
}

export function useLandingPageDispatch() {
  return useContext(LandingPageDispatchContext);
}

type Window = globalThis.Window &
  typeof globalThis & {
    piAId: string;
    piCId: string;
    piHostname: string;
  };
export function installPardot() {
  (window as Window).piAId = "1016852";
  (window as Window).piCId = "2189";
  (window as Window).piHostname = "pi.pardot.com";

  function async_load() {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = ("https:" === document.location.protocol ? "https://pi" : "http://cdn") + ".pardot.com/pd.js";
    const c = document.getElementsByTagName("script")[0];
    c.parentNode?.insertBefore(s, c);
  }
  window.addEventListener("load", async_load, false);
}

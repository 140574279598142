export const urlToExtension = (url?: string): string => {
  if (!url) {
    return "";
  }
  const matchedFileName = url.match(/^(?:[^:/?#]+:)?(?:\/\/[^/?#]*)?(?:([^?#]*\/)([^/?#]*))?(\?[^#]*)?(?:#.*)?$/) ?? [];
  const [, , fileName] = matchedFileName.map((match) => match ?? "");
  const matchedExt = fileName.match(/^(.+?)(\.[^.]+)?$/) ?? [];
  const [, , ext] = matchedExt.map((match) => match ?? "");
  return ext;
};

export const isValidURL = (urlString: string): boolean => {
  try {
    new URL(urlString);
    return true;
  } catch {
    return false;
  }
};

// refName から拡張子を取得して、filename の末尾に連結したファイル名を返す。
// filename にすでに拡張子が含まれる場合は、それを除いてから連結する。
//
// NOTE: <a> タグの download 属性を指定する時に有用。Firefox は、Chrome や Safari のように
// ファイル名に拡張子がなかったときに、自動で生成してくれないため。
export const generateFilename = (filename: string, refName: string): string => {
  const refComponents = refName.split(".");
  if (refComponents.length <= 1) {
    return filename;
  }
  const extension = refComponents[refComponents.length - 1];
  const components = filename.split(".");
  if (components.length > 1) {
    components.pop();
  }
  components.push(extension);
  return components.join(".");
};

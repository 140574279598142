import { createContext, FC, useCallback, useContext, useEffect, useState } from "react";

import voiceApi from "../api/voice";
import { UserContext } from "./UserContext";
import { User } from "../types/user";
import { useOrganization } from "./OrganizationControlledContext";

type OrgUserContextType =
  | {
      isLoaded: false;
      orgUser?: undefined;
    }
  | {
      isLoaded: true;
      orgUser: User;
    };

const orgUserContextInitialState: OrgUserContextType = {
  isLoaded: false,
};

const OrgUserContext = createContext<OrgUserContextType>(orgUserContextInitialState);

type OrgUserRefreshContextType = () => Promise<User | null | undefined>;

const OrgUserRefreshContext = createContext<OrgUserRefreshContextType>(() => Promise.resolve(null));

export const OrgUserContextProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, setState] = useState<OrgUserContextType>(orgUserContextInitialState);
  const {
    userState: { user },
  } = useContext(UserContext);
  const org = useOrganization();

  const refresh = useCallback(async () => {
    if (!user) return null;
    const orgUser = org && (await voiceApi.getOrgUser(org?.id, user.uid));
    if (orgUser) setState({ isLoaded: true, orgUser });

    return orgUser;
  }, [org, user]);

  // ログイン・ログアウト時
  useEffect(() => {
    if (user) {
      refresh().then().catch();
    } else {
      setState({ isLoaded: false, orgUser: undefined });
    }
  }, [refresh, user]);

  return (
    <OrgUserContext.Provider value={state}>
      <OrgUserRefreshContext.Provider value={refresh}>{children}</OrgUserRefreshContext.Provider>
    </OrgUserContext.Provider>
  );
};

export const useOrgUser = (): OrgUserContextType & {
  refreshOrgUser: OrgUserRefreshContextType;
} => {
  const orgUser = useContext(OrgUserContext);
  const refresh = useContext(OrgUserRefreshContext);
  return {
    ...orgUser,
    refreshOrgUser: refresh,
  };
};

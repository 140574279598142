/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";
import { useSearchParams } from "next/navigation";

export function useTypedSearchParams<T = any>() {
  const searchParams = useSearchParams();
  const searchParamsObj: { [key: string]: string } = {};

  if (searchParams) {
    for (const [key, val] of searchParams) {
      searchParamsObj[key] = val;
    }
  }

  return searchParamsObj as any as T;
}

export const useSearchParamsString = () => {
  const searchParams = useSearchParams();
  return searchParams?.toString() ? "?" + searchParams.toString() : "";
};

export const objectToSearchParamsString = (obj: Record<string, string | number | boolean | undefined>) => {
  const queryParams: string[] = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (value !== undefined) {
        queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
  }

  return "?" + queryParams.join("&");
};

import { uniqueNamesGenerator, colors, names } from "unique-names-generator";

export const generateName = () => {
  return uniqueNamesGenerator({
    dictionaries: [names, colors],
    style: "capital",
    separator: " ",
    length: 2,
  });
};

import React, { createContext, useReducer, Dispatch } from "react";

import { urlToExtension } from "../utils/url";
import { generateFilename } from "../utils/generateFilename";
import { Slice } from "@tiptap/pm/model";
import { ExportedTimestampHighlight } from "@rimo/frontend/features/note/lib/document-editor";

interface StateProps {
  show: boolean;
  url: string;
  filename: string;
  getDocumentNodeSlice: () => Slice | undefined;
  getAnnotations: () => ExportedTimestampHighlight[];
  getAssets: () => { [key: string]: { url: string } };
}

export type ActionProps =
  | {
      type: "DOWNLOAD";
      payload: {
        url: string;
        filename: string;
      };
    }
  | {
      type: "DISMISS";
      payload: unknown;
    }
  | {
      type: "SET_DOCUMENT_NODE_SLICE_GETTER";
      payload: StateProps["getDocumentNodeSlice"];
    }
  | {
      type: "SET_ASSETS_GETTER";
      payload: StateProps["getAssets"];
    }
  | {
      type: "SET_ANNOTATIONS_GETTER";
      payload: StateProps["getAnnotations"];
    };

// sometime url is https://xxx/yyy.mp3 and filename is zzz.m4a
// at that time, returns zzz.mp3
const correctedFilename = (url: string, filename: string): string => {
  const ext = urlToExtension(url);
  return generateFilename(filename, ext);
};

const reducer: React.Reducer<StateProps, ActionProps> = (state, { type, payload }): StateProps => {
  switch (type) {
    case "DOWNLOAD":
      return {
        ...state,
        show: true,
        url: payload.url,
        filename: correctedFilename(payload.url, payload.filename),
      };
    case "DISMISS":
      return {
        ...state,
        show: false,
        url: "",
        filename: "",
      };
    case "SET_DOCUMENT_NODE_SLICE_GETTER":
      return {
        ...state,
        getDocumentNodeSlice: payload,
      };
    case "SET_ASSETS_GETTER":
      return {
        ...state,
        getAssets: payload,
      };
    case "SET_ANNOTATIONS_GETTER":
      return {
        ...state,
        getAnnotations: payload,
      };
    default:
      return Object.assign({}, state);
  }
};

const initialState: StateProps = {
  show: false,
  url: "",
  filename: "",
  getDocumentNodeSlice: () => undefined,
  getAssets: () => ({}),
  getAnnotations: () => [],
};

export const DownloadContext = createContext<{ state: StateProps; dispatch: Dispatch<ActionProps> }>({
  state: initialState,
  /* eslint-disable-next-line @typescript-eslint/no-empty-function */
  dispatch: (() => {}) as Dispatch<ActionProps>,
});

export const DownloadProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <DownloadContext.Provider value={{ state, dispatch }}>{children}</DownloadContext.Provider>;
};

"use client";

import { usePathname } from "next/navigation";
import { FC, PropsWithChildren, useContext, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { UserContext } from "../contexts/UserContext";
import Sentry from "../utils/sentry";
import { useInjectUserIntoReactApp } from "../hooks/useInjectUserIntoReactApp";
import { onPageReload } from "../services/firebase/auth/event";

const authenticationUrls = [
  "/about", // メールアドレス・パスワードでのユーザー認証
  // "/login/redirect", // リダイレクトによるSNSを使ったユーザー認証
];

export const FirebaseAuth: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const pathname = usePathname();
  const inject = useInjectUserIntoReactApp();
  const { userState, userDispatch } = useContext(UserContext);
  const { isLoaded, user: userInReactApp } = userState;

  /**
   * Firebase Authenticationの状態変化を管理監視(ページリロード、ログアウト、未ログイン)
   */
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      getAuth(),
      (userInFirebase) => {
        if (!userInReactApp && !userInFirebase && !isLoaded) {
          // 未ログイン時
          // console.debug("[Firebase Auth onAuthStateChanged] 未ログインユーザー", userInFirebase);
          userDispatch({ type: "INIT_AS_UNKNOWN" });
        } else if (!userInReactApp && userInFirebase) {
          const isSelfAuthManagedUrl = authenticationUrls.includes(pathname || "");
          if (isSelfAuthManagedUrl) {
            // ユーザー認証の入り口となるページではサインアップ・サインイン時に当該if-blockで `onPageReload()` で余計な処理を回避する
            // リダイレクトを使ったソーシャルログイン時は当該callbackとリロード処理が両方実行されるため、当該callbackをスキップ
          } else {
            // カスタムトークンによるログイン時
            // ページリロード時(React上にユーザーが存在せず、Firebase上にユーザーが存在する)
            onPageReload(userInFirebase).then((result) => inject(result));
          }
        } else if (userInReactApp && !userInFirebase) {
          // ログアウト用として利用しない(が、今後タブの非アクティブな状態として扱うことは可能)
          // 複数タブ利用時に、利用していないタブがリロード等により一瞬当該if-blockに該当し、ログアウト処理が実施されてしまう
        }
        // todo ユーザー情報が更新された際の処理
      },
      (err) => {
        Sentry.captureException(err);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [pathname, userInReactApp, inject, userDispatch, isLoaded]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

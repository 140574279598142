import React, { createContext, useReducer, Dispatch, useContext } from "react";
import { Services } from "../types/services";

interface StateProps {
  show: boolean;
  isOpenMediaUploader: boolean;
  isUploading: boolean;
  progress: number;
  kind: "recorder" | "create";
  service: Services;
}

type Action =
  | { type: "SHOW" }
  | { type: "DISMISS" }
  | { type: "UPLOADING"; payload: boolean }
  | { type: "DIALOG"; service: Services; payload: boolean }
  | { type: "RECORDER"; payload: boolean }
  | { type: "PROGRESS_UPDATE"; progress: number };

const reducer: React.Reducer<StateProps, Action> = (state, action): StateProps => {
  switch (action.type) {
    case "SHOW":
      return { ...state, show: true };
    case "DISMISS":
      return { ...state, show: false };
    case "UPLOADING":
      return { ...state, isUploading: action.payload };
    case "DIALOG":
      return { ...state, isOpenMediaUploader: action.payload, kind: "create", service: action.service };
    case "RECORDER":
      return { ...state, isOpenMediaUploader: action.payload, kind: "recorder" };
    case "PROGRESS_UPDATE":
      return { ...state, progress: action.progress };
    default:
      return Object.assign({}, state);
  }
};

const initialState: StateProps = {
  show: false,
  isOpenMediaUploader: false,
  isUploading: false,
  progress: 0,
  kind: "create",
  service: "note",
};

export const VoiceMediaContext = createContext<{ state: StateProps; dispatch: Dispatch<Action> }>({
  state: initialState,
  /* eslint-disable-next-line @typescript-eslint/no-empty-function */
  dispatch: (() => {}) as Dispatch<Action>,
});

export const VoiceMediaProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <VoiceMediaContext.Provider value={{ state, dispatch }}>{children}</VoiceMediaContext.Provider>;
};

export const useVoiceMediaContext = () => {
  const ctx = useContext(VoiceMediaContext);
  return { voiceMediaState: ctx.state, voiceMediaDispatch: ctx.dispatch };
};

import { isLocaleCodeFormat } from "@rimo/i18n/settings";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOverrideThemeMode = (pathname: string | null | undefined, query: Record<string, any>) => {
  try {
    if (!pathname) return "light";
    // /[lng]/path のような形式の場合、[lng] を除いたパスを取得
    const paths = pathname.split("/");
    const path = isLocaleCodeFormat(paths[1] ?? "") ? `/${paths.slice(2).join("/")}` : pathname;

    if (/^\/about\//.test(path)) {
      return "light";
    }
    if (/^\/usecases\//.test(path)) {
      return "light";
    }
    if (/^\/form\//.test(path)) {
      return "light";
    }
    if (/^\/summary\//.test(path)) {
      return "light";
    }
    if (/^\/case-studies\//.test(path)) {
      return "light";
    }
    if (path === "/blogs" || /^\/blogs\//.test(path)) {
      return "light";
    }
    if (EXCLUDED_PAGES.includes(path)) {
      return "light";
    }
    if (query.theme === "light" || query.theme === "dark") {
      return query.theme;
    }
  } catch (e) {
    console.warn(e);
  }
  return null;
};

const EXCLUDED_PAGES = [
  "/dashboard",
  "/devices",
  "/google/redirect",
  "/media",
  "/microsoft/redirect",
  "/zoom/redirect",
  "/profile",
  "/rooms/[roomId]",
  "/check_auth",
  "/ping",
  "/",
];

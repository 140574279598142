import { FacebookPixelId } from "../../constants/services";

export const facebookPixelCompleteRegistration = async (uid: string) => {
  if (!FacebookPixelId) {
    return;
  }
  import("react-facebook-pixel")
    .then((module) => module.default)
    .then((ReactPixel) => {
      if (FacebookPixelId) {
        ReactPixel.init(FacebookPixelId);
        ReactPixel.track("CompleteRegistration", { user_id: uid });
      }
    });
};

export const facebookPixelPurchase = async (amount: number, mediaType: string, uid: string) => {
  // if (!FacebookPixelId || amount <= 0) {
  if (!FacebookPixelId) {
    return;
  }
  import("react-facebook-pixel")
    .then((module) => module.default)
    .then((ReactPixel) => {
      if (FacebookPixelId) {
        ReactPixel.init(FacebookPixelId);
        ReactPixel.track("Purchase", { currency: "JPY", value: amount, content_name: mediaType, user_id: uid });
      }
    });
};

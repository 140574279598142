import React, { useReducer } from "react";
import { SelfProps, RoomProps, MemberInfo, ChatMessage, RoomMessage, MemberProps, ViewType } from "../types/room";
import { Client } from "../services/Client";
import { generateName } from "../utils/generateName";
import { SpeakerFinder } from "../utils/SpeakerFinder";
import { Transcript, RoomShowSttTranscriptType } from "../types/room";
import { useRoomActionCreators, ActionCreatorProps } from "../hooks/useRoomActionCreators";
import { v4 as uuidv4 } from "uuid";
import { NoteProps } from "../types";
import { STTClient } from "../services/STTClient";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import { RoomState } from "../services/Client";
import { RoomLog } from "../types/room";

const MAX_ROOMLOG_SIZE = 100;

export interface RoomStateProps {
  isJoined: boolean;
  client?: Client;
  speakerFinder: SpeakerFinder;
  selfInfo: SelfProps;
  roomInfo: RoomProps;
  memberInfo: MemberInfo;
  logs: RoomLog[];
}

const initialState: RoomStateProps = {
  isJoined: false,
  speakerFinder: new SpeakerFinder(),
  selfInfo: {
    memberId: uuidv4(),
    name: generateName(),
    viewType: "SPEAKER",
    videoActive: true,
    audioActive: true,
    speakerActive: true,
    showSttTranscriptType: "ALL",
    videoDevices: [],
    speakerDevices: [],
    micDevices: [],
    isHost: false,
    stream: undefined,
    getUserMediaDenied: false,
    showChat: false,
    deviceAngle: 0,
    personalColorHue: Math.floor(Math.random() * 45) * 8,
    screenTrackIds: null,
    recordActive: false,
  },
  roomInfo: {
    breakOutRoomEnabled: false,
    chatEnabled: true,
    chatMessages: [],
    transcripts: [],
  },
  memberInfo: {},
  logs: [],
};

const generateTranscriptFromChatmessage = (message: ChatMessage): Transcript => {
  return {
    id: message.id,
    sendAt: message.sendAt,
    content: message.content,
    speakerName: message.sendUser?.name,
    hue: message.hue || 0,
    stt: message.isTranscript,
  };
};

const updateStateWithNewMessage = (newMessage: ChatMessage, state: RoomStateProps): RoomStateProps => {
  let newTranscripts: Transcript[] = new Array(0).concat(state.roomInfo.transcripts);
  const newTranscript = generateTranscriptFromChatmessage(newMessage);
  const existTranscript = newTranscripts.find((t) => t.id === newTranscript.id);
  if (existTranscript) {
    newTranscripts = newTranscripts.map((t) => (t.id === newTranscript.id ? newTranscript : t));
  } else {
    newTranscripts.push(newTranscript);
  }

  let chatMessages = [...state.roomInfo.chatMessages];
  const existChatMessage = chatMessages.find((c) => c.id === newMessage.id);
  if (existChatMessage) {
    chatMessages = chatMessages.map((chatMessage) => (chatMessage.id === newMessage.id ? newMessage : chatMessage));
  } else {
    chatMessages.push(newMessage);
  }

  return {
    ...state,
    roomInfo: {
      ...state.roomInfo,
      chatMessages,
      transcripts: newTranscripts,
    },
  };
};

export type RoomActionProps =
  | { type: "JOIN"; payload: any } // eslint-disable-line @typescript-eslint/no-explicit-any
  | { type: "SET_NOTE"; payload: { note?: NoteProps } }
  | { type: "UPDATE_MEMBER"; payload: { target: string; info: MemberProps } }
  | { type: "SET_CLIENT"; payload: { client: Client } }
  | { type: "SET_NAME"; payload: { name: string } }
  | { type: "SET_VIEW_TYPE"; payload: { viewType: ViewType } }
  | { type: "ADD_STREAM"; payload: { target: string } }
  | { type: "REMOVE_SCREEN_STREAM"; payload: any } // eslint-disable-line @typescript-eslint/no-explicit-any
  | { type: "SET_MEDIA_ACTIVE"; payload: { videoActive: boolean; audioActive: boolean } }
  | { type: "SWITCH_MIC"; payload: { deviceId: string } }
  | { type: "SWITCH_VIDEO"; payload: { deviceId: string } }
  | { type: "SWITCH_SPEAKER"; payload: { deviceId: string } }
  | { type: "SET_SCREEN_SHARED"; payload: { screenTrackIds: [string] | null } }
  | { type: "UPDATE_SELF_STREAM"; payload: { stream: MediaStream | null; audioActive: boolean; videoActive: boolean } }
  | { type: "GET_USER_MEDIA_DENIED"; payload: any } // eslint-disable-line @typescript-eslint/no-explicit-any
  | { type: "UPDATE_DEVICES"; payload: { devices: MediaDeviceInfo[] } }
  | { type: "SET_REMOTE_STREAM"; payload: { memberId: string; stream: MediaStream } }
  | { type: "REMOVE_REMOTE_STREAM_TRACK"; payload: { memberId: string; track: MediaStreamTrack } }
  | { type: "ADD_MEMBER"; payload: { memberId: string; peerConnection: RTCPeerConnection } }
  | { type: "REMOVE_MEMBER"; payload: { memberId: string } }
  | { type: "UPDATE_REMOTE_STREAM_AUDIO_STATUS"; payload: { memberId: string; audioActive: boolean } }
  | { type: "SET_REMOTE_SCREEN_STREAM"; payload: { memberId: string; isActive: boolean; screenTrackIds: [string] } }
  | {
      type: "UPDATE_REMOTE_SCREEN_STREAM";
      payload: { message: { screenTrackIds: [string]; memberId: string; isActive: boolean } };
    }
  | { type: "REQUEST_MEMBER_INFO"; payload: { to?: string } }
  | { type: "SEND_CHAT"; payload: ChatMessage }
  | { type: "RECEIVE_CHAT"; payload: ChatMessage }
  | { type: "SET_STT_CLIENT"; payload: { sttClient?: STTClient } }
  | { type: "SET_SHOW_CHAT"; payload: { showChat: boolean } }
  | { type: "SET_DEVICE_ANGLE"; payload: { deviceAngle: number } }
  | { type: "SEND_NEGOTITATION"; payload: any } // eslint-disable-line @typescript-eslint/no-explicit-any
  | { type: "SET_NEGOTIATION"; payload: any } // eslint-disable-line @typescript-eslint/no-explicit-any
  | { type: "START_RECORDING"; payload: any } // eslint-disable-line @typescript-eslint/no-explicit-any
  | { type: "STOP_RECORDING"; payload: any } // eslint-disable-line @typescript-eslint/no-explicit-any
  | { type: "SET_SHOW_STT_TRANSCRIPT"; payload: RoomShowSttTranscriptType }
  | { type: "UPDATE_WEB_SOCKET_READY_STATE"; payload: { readyState: number } }
  | { type: "SET_SESSION_ID"; payload: { sessionId: string } }
  | { type: "UPDATE_ROOM_STATE"; payload: RoomState }
  | { type: "SAVE_LOG"; payload: RoomLog };

const doReducer: React.Reducer<RoomStateProps, RoomActionProps> = (state, action): RoomStateProps => {
  switch (action.type) {
    case "JOIN": {
      return {
        ...state,
        isJoined: true,
      };
    }
    case "SET_NOTE": {
      return {
        ...state,
        roomInfo: {
          ...state.roomInfo,
          note: action.payload.note,
        },
      };
    }
    case "UPDATE_MEMBER": {
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          [action.payload.target]: {
            ...state.memberInfo[action.payload.target],
            ...action.payload.info,
            memberId: action.payload.target,
          },
        },
      };
    }
    case "SET_CLIENT": {
      return {
        ...state,
        client: action.payload.client,
      };
    }
    case "SET_NAME": {
      return {
        ...state,
        selfInfo: {
          ...state.selfInfo,
          name: action.payload.name,
        },
      };
    }
    case "SET_VIEW_TYPE": {
      return {
        ...state,
        selfInfo: {
          ...state.selfInfo,
          viewType: action.payload.viewType,
        },
      };
    }
    case "ADD_STREAM": {
      state.client && state.client.addScreenTrack();
      return state;
    }
    case "REMOVE_SCREEN_STREAM": {
      state.client?.stopScreenShare();
      return state;
    }
    case "SET_MEDIA_ACTIVE": {
      const videoActive = action.payload.videoActive;
      const audioActive = action.payload.audioActive;
      return {
        ...state,
        selfInfo: {
          ...state.selfInfo,
          videoActive,
          audioActive,
        },
      };
    }
    case "SWITCH_MIC": {
      const { deviceId } = action.payload;
      if (deviceId === state.selfInfo.activeMicDeviceId) {
        return state;
      }
      const { videoActive, audioActive, activeVideoDeviceId } = state.selfInfo;
      const config = { videoActive, audioActive, micDeviceId: deviceId, videoDeviceId: activeVideoDeviceId };
      state.client?.switchDevice(config);
      return {
        ...state,
        selfInfo: {
          ...state.selfInfo,
          activeMicDeviceId: deviceId,
        },
      };
    }
    case "SWITCH_VIDEO": {
      const { deviceId } = action.payload;
      if (deviceId === state.selfInfo.activeVideoDeviceId) {
        return state;
      }
      const { videoActive, audioActive, activeMicDeviceId } = state.selfInfo;
      const config = { videoActive, audioActive, micDeviceId: activeMicDeviceId, videoDeviceId: deviceId };
      state.client?.switchDevice(config);
      return {
        ...state,
        selfInfo: {
          ...state.selfInfo,
          activeVideoDeviceId: deviceId,
        },
      };
    }
    case "SWITCH_SPEAKER": {
      const { deviceId } = action.payload;
      return {
        ...state,
        selfInfo: {
          ...state.selfInfo,
          activeSpeakerDeviceId: deviceId,
        },
      };
    }
    case "SET_SCREEN_SHARED": {
      return {
        ...state,
        selfInfo: {
          ...state.selfInfo,
          screenTrackIds: action.payload.screenTrackIds,
        },
      };
    }
    case "UPDATE_SELF_STREAM": {
      let stream = action.payload.stream;
      const newStreamTrackIds = stream
        ?.getTracks()
        .map((track) => track.id)
        ?.sort();
      const oldStreamTrackIds = state.selfInfo.stream
        ?.getTracks()
        .map((track) => track.id)
        ?.sort();
      if (!isEqual(oldStreamTrackIds, newStreamTrackIds)) {
        /* create new stream so that React can detect the change of stream */
        const newStream = new MediaStream();
        stream?.getTracks().forEach((track) => {
          newStream.addTrack(track);
        });
        stream = newStream;
      }
      state.speakerFinder.setStream(state.selfInfo.memberId, stream);
      return {
        ...state,
        selfInfo: {
          ...state.selfInfo,
          stream: stream ?? undefined,
          audioActive: action.payload.audioActive ?? state.selfInfo.audioActive,
          videoActive: action.payload.videoActive ?? state.selfInfo.videoActive,
        },
      };
    }
    case "GET_USER_MEDIA_DENIED": {
      return {
        ...state,
        selfInfo: {
          ...state.selfInfo,
          getUserMediaDenied: true,
        },
      };
    }
    case "UPDATE_DEVICES": {
      const devices: MediaDeviceInfo[] = action.payload.devices;
      return {
        ...state,
        selfInfo: {
          ...state.selfInfo,
          videoDevices: devices.filter((d) => {
            return d.kind === "videoinput";
          }),
          speakerDevices: devices.filter((d) => {
            return d.kind === "audiooutput";
          }),
          micDevices: devices.filter((d) => {
            return d.kind === "audioinput";
          }),
        },
      };
    }
    case "SET_REMOTE_STREAM": {
      const audioTrack: MediaStreamTrack = action.payload.stream.getAudioTracks()[0];
      const audioStream = audioTrack ? new MediaStream() : undefined;
      if (audioTrack) {
        audioStream?.addTrack(audioTrack);
      }
      state.speakerFinder.setStream(action.payload.memberId, action.payload.stream);
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          [action.payload.memberId]: {
            ...state.memberInfo[action.payload.memberId],
            stream: action.payload.stream,
            audioStream,
            videoActive: true,
            audioActive: true,
          },
        },
      };
    }
    case "REMOVE_REMOTE_STREAM_TRACK": {
      const memberId = action.payload.memberId;
      const curStream = state.memberInfo[memberId].stream;
      const newStream = new MediaStream();
      curStream?.getTracks().forEach((track) => {
        if (track.id !== action.payload.track.id) {
          newStream.addTrack(track);
        }
      });
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          [action.payload.memberId]: {
            ...state.memberInfo[action.payload.memberId],
            stream: newStream,
          },
        },
      };
    }
    case "ADD_MEMBER": {
      const initialMemberInfo: MemberProps = {
        memberId: action.payload.memberId,
        name: "(unknown)",
        videoActive: true,
        audioActive: true,
        isHost: false,
        deviceAngle: 0,
        screenTrackIds: null,
        peerConnection: action.payload.peerConnection,
      };
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          [action.payload.memberId]: {
            ...initialMemberInfo,
            memberId: action.payload.memberId,
          },
        },
      };
    }
    case "REMOVE_MEMBER": {
      return {
        ...state,
        memberInfo: omit(state.memberInfo, action.payload.memberId),
      };
    }
    case "UPDATE_REMOTE_STREAM_AUDIO_STATUS": {
      const message: RoomMessage = {
        action: "SET_REMOTE_STREAM_AUDIO_STATUS",
        from: state.selfInfo.memberId || "UNDEFINED",
        to: "OTHERS",
        payload: action.payload,
      };
      state.client?.sendDataChannel(action.payload.memberId, message);
      return state;
    }
    case "SET_REMOTE_SCREEN_STREAM": {
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          [action.payload.memberId]: {
            ...state.memberInfo[action.payload.memberId],
            screenTrackIds: action.payload.isActive ? action.payload.screenTrackIds : null,
          },
        },
      };
    }
    case "UPDATE_REMOTE_SCREEN_STREAM": {
      const message: RoomMessage = {
        action: "SET_REMOTE_SCREEN_STREAM",
        from: state.selfInfo.memberId || "UNDEFINED",
        to: "OTHERS",
        payload: action.payload.message,
      };
      state.client?.broadcastDataChannel(message);
      return state;
    }
    case "REQUEST_MEMBER_INFO": {
      const message: RoomMessage = {
        action: "REQUEST_MEMBER_INFO",
        from: state.selfInfo.memberId || "UNDEFINED",
        to: "OTHERS",
        payload: {},
      };
      state.client?.broadcastDataChannel(message);
      return state;
    }
    case "SEND_CHAT": {
      const newMessage: ChatMessage = action.payload;
      return updateStateWithNewMessage(newMessage, state);
    }
    case "RECEIVE_CHAT": {
      const newMessage: ChatMessage = action.payload;
      return updateStateWithNewMessage(newMessage, state);
    }
    case "SET_STT_CLIENT": {
      action.payload.sttClient?.setAudioStream(state.selfInfo.stream ?? null);
      return {
        ...state,
        selfInfo: {
          ...state.selfInfo,
          sttClient: action.payload.sttClient,
        },
      };
    }
    case "SET_SHOW_CHAT": {
      return {
        ...state,
        selfInfo: {
          ...state.selfInfo,
          showChat: action.payload.showChat,
        },
      };
    }
    case "SET_DEVICE_ANGLE": {
      return {
        ...state,
        selfInfo: {
          ...state.selfInfo,
          deviceAngle: action.payload.deviceAngle,
        },
      };
    }
    case "SEND_NEGOTITATION": {
      const message: RoomMessage = {
        action: "NEGOTIATION",
        from: state.selfInfo.memberId || "UNDEFINED",
        to: "SERVER",
        payload: action.payload,
      };
      state.client?.broadcastDataChannel(message);
      return state;
    }
    case "SET_NEGOTIATION": {
      /*
      const desc = new RTCSessionDescription(action.payload);
      console.log("setRemoteDescription on Datachannel", desc);
      state.client?.setRemoteDescription(desc);
       */
      return state;
    }
    case "START_RECORDING": {
      state.selfInfo.sttClient?.updateRecording(true);
      return {
        ...state,
        selfInfo: {
          ...state.selfInfo,
          recordActive: true,
        },
      };
    }
    case "STOP_RECORDING": {
      state.selfInfo.sttClient?.updateRecording(false);
      return {
        ...state,
        selfInfo: {
          ...state.selfInfo,
          recordActive: false,
        },
      };
    }
    case "SET_SHOW_STT_TRANSCRIPT": {
      return {
        ...state,
        selfInfo: {
          ...state.selfInfo,
          showSttTranscriptType: action.payload,
        },
      };
    }
    case "UPDATE_WEB_SOCKET_READY_STATE": {
      return {
        ...state,
        roomInfo: {
          ...state.roomInfo,
          webSocketReadyState: action.payload.readyState,
        },
      };
    }
    case "SET_SESSION_ID": {
      return {
        ...state,
        roomInfo: {
          ...state.roomInfo,
          sessionId: action.payload.sessionId,
        },
      };
    }
    case "UPDATE_ROOM_STATE": {
      state.client?.updateRoomState(action.payload);
      return state;
    }

    case "SAVE_LOG": {
      const lastLog = state.logs[state.logs.length - 1];
      if (lastLog?.messages === action.payload.messages) {
        return state;
      }
      const logs = [...state.logs];
      logs.push(action.payload);
      if (logs.length > MAX_ROOMLOG_SIZE) {
        logs.shift();
      }
      return {
        ...state,
        logs,
      };
    }
  }
};

let currentAction: RoomActionProps | null = null;

const reducer: React.Reducer<RoomStateProps, RoomActionProps> = (state, action): RoomStateProps => {
  if (currentAction) {
    throw new Error(
      "Don't call dispatch from the reducer as it will rollback the state. action: " +
        JSON.stringify(action) +
        ", currentAction:" +
        JSON.stringify(currentAction)
    );
  }
  currentAction = action;
  const newState = doReducer(state, action);
  currentAction = null;
  return newState;
};

export const RoomStateContext = React.createContext<RoomStateProps>(initialState);

export const RoomDispatchContext = React.createContext<React.Dispatch<RoomActionProps>>((() => {
  // noop
}) as React.Dispatch<RoomActionProps>);

export const RoomActionCreatorsContext = React.createContext<ActionCreatorProps>({} as ActionCreatorProps);

export const RoomProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [roomState, dispatch] = useReducer(reducer, initialState);
  const actionCreators = useRoomActionCreators(roomState, dispatch);
  return (
    <RoomStateContext.Provider value={roomState}>
      <RoomDispatchContext.Provider value={dispatch}>
        <RoomActionCreatorsContext.Provider value={actionCreators}>{children}</RoomActionCreatorsContext.Provider>
      </RoomDispatchContext.Provider>
    </RoomStateContext.Provider>
  );
};
